export const OFFERS_BLOCKS = [
  {
    id: 'offers-block-1',
    title: 'Заработок вместе с творчеством',
    subtitle:
      'Ежемесячно выплачиваем до 70% вознаграждения, начиная с первой публикации',
    icon: 'hobby',
  },
  {
    id: 'offers-block-2',
    title: 'Поддержка на всех этапах',
    subtitle:
      'Обеспечим удобный процесс публикации, предоставим услуги, защитим от пиратов',
    icon: 'support',
  },
  {
    id: 'offers-block-3',
    title: 'Свобода форматов и жанров',
    subtitle:
      'Публикуйте книги в любых жанрах по главам и целиком, аудиокниги или закажите тираж',
    icon: 'formats',
  },
];
