import Image from 'next/image';
import type { FC } from 'react';

import { Illustration } from 'components/basic/Illustration';
import {
  BodyL700,
  BodyL400,
  H4,
  HighlightTitle,
  HighlightSubtitle,
} from 'components/basic/Typography';

import { typografFixText } from 'utils/typograf';

import { SUCCESS_STORIES } from './constants';

import s from './SuccessStoriesBlock.module.scss';

export const FifthStory: FC = () => {
  const story = SUCCESS_STORIES[4];

  return (
    <>
      <div className={s.about}>
        <Image src={story.src} height={56} width={56} alt={story.name} />
        <div className={s.info}>
          <BodyL700>{story.name}</BodyL700>
          <BodyL400 className={s.genre}>{story.genre}</BodyL400>
        </div>
      </div>
      <HighlightTitle className={s.modalTitle}>
        {typografFixText(story.text)}
      </HighlightTitle>
      <H4>{typografFixText('До того, как я стал автором Литрес')}</H4>
      <BodyL400 className={s.modalText}>
        {typografFixText(
          'В писательство я пришел DevOps-инженером с восьмилетним опытом работы. Это случилось в январе 2022 года. Постоянная работа оставляла мало сил, поэтому процесс обычно шел по выходным, когда о работе можно было забыть. Так я за два месяца написал четверть материала. А потом уволился из IT.',
        )}
      </BodyL400>
      <BodyL400 className={s.modalText}>
        {typografFixText(
          'В начале карьеры автора встает вопрос, куда идти: в самиздат или классическое издательство. Я выбрал самиздат и вот почему:',
        )}
      </BodyL400>
      <BodyL400 className={s.modalText}>
        <div>1. Хочу релиз прямо сейчас!</div>
        <div>2. Это современный формат</div>
        <div>
          3. Хочу сохранить исключительные права на свое творчество и решать
          самостоятельно, что мне делать с книгой
        </div>
      </BodyL400>
      <div className={s.modalQuote}>
        <Illustration
          imageType="quote"
          width={9}
          height={7}
          className={s.modalQuoteTop}
        />
        <HighlightSubtitle className={s.modalQuoteText}>
          {typografFixText(
            'Платформа Литрес Самиздат дала возможность сделать первый и самый важный шаг',
          )}
        </HighlightSubtitle>
        <Illustration
          imageType="quote"
          width={9}
          height={7}
          className={s.modalQuoteBottom}
        />
      </div>
      <H4>{typografFixText('Почему я опубликовал книгу на Литрес?')}</H4>
      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Я никогда не считал писательство своим кредо, но написать что-то масштабное хотел всю жизнь. И вот, мечта сбылась. Из айтишника я стал настоящим автором. Первая книга «Кауч» вышла 29 апреля 2022 года. А в начале 2023 я опубликовал на Литрес еще одно произведение. Провел презентацию книг и даже перевел их на английский язык. Платформа Литрес Самиздат дала возможность сделать первый и самый важный шаг. И останавливаться я не собираюсь.',
        )}
      </BodyL400>
    </>
  );
};
