import { MainPage } from 'modules/main-page';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { publisherUrls } from 'constants/pagesUrls';

import { Page } from 'components/layouts/Page';

import { TAppState } from 'sp-redux';

export default function Home(): JSX.Element {
  const userInfo = useSelector((state: TAppState) => state.userInfo);
  const router = useRouter();

  useEffect(() => {
    const isPublisher = userInfo.is_publisher;

    if (isPublisher) {
      router.replace(publisherUrls.statistics.salesRoyalty);
    }
  }, []);

  return (
    <Page fullWidth>
      <MainPage />
    </Page>
  );
}
