import { FC } from 'react';

import { pageUrls } from 'constants/pagesUrls';

import { Button } from 'components/UIKit/Button';
import { Illustration } from 'components/basic/Illustration';
import { H3, HighlightTitle, T } from 'components/basic/Typography';
import { CollapseList } from 'components/complex/CollapseList';

import { createUTMUrl } from 'utils/createUTMUrl';

import { ebukvaData } from './collapseData';

import s from './EBukvaPromo.module.scss';

const URL = createUTMUrl(pageUrls.ebukva.index, {
  source: 'selfpub',
  medium: 'main',
  campaign: 'ebukva2024',
});

export const EBukvaPromo: FC = () => {
  return (
    <div className={s.root}>
      <div className={s.wrapper}>
        <div className={s.content}>
          <HighlightTitle Tag="h2" className={s.title}>
            <T>Электронная буква. 7&nbsp;сезон</T>
          </HighlightTitle>
          <H3 className={s.subtitle}>
            <T>
              Крупнейшая в России ежегодная литературная премия в области
              электронных и аудиокниг
            </T>
          </H3>
          <CollapseList
            collapseData={ebukvaData}
            className={s.accordeon}
            arrowPosition="right"
            showAllBorders
            headerClassName={s.accordeonHeader}
            dividerClassName={s.accordeonDivider}
            bodyClassName={s.accordeonBody}
            blockClassName={s.accordeonBlock}
            isCustomBlock
          />
          <Button href={URL} target="_blank" className={s.button}>
            Новости премии
          </Button>
        </div>

        <Illustration
          className={s.cupImage}
          imageType="cup"
          width={408}
          height={426}
        />
      </div>
    </div>
  );
};
