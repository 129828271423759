import { logFirebaseEvent } from 'analytics/firebase';
import {
  sendGoogleAnalytics,
  sendYandexAnalytics,
} from 'analytics/sendAnalytics';
import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FC, ReactNode, UIEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MIN_WINDOW_WITH_FOR_AUTH_MODAL_AND_BURGER } from 'constants/numbers';
import { pageUrls } from 'constants/pagesUrls';

import { Button } from 'components/UIKit/Button';
import { HighlightTitle } from 'components/basic/Typography';

import type { TAppState } from 'sp-redux';
import { openModal } from 'sp-redux/slices/ui';
import { EUiModalTypes } from 'sp-redux/slices/ui/types';

import { isBrowser } from 'utils/environment';

import s from './FinalBlock.module.scss';

export const FinalBlock: FC = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { userInfo } = useSelector((appState: TAppState) => ({
    userInfo: appState.userInfo,
  }));

  const eventParams = {
    source: 'main_want_your_story_was_read',
    user_type: userInfo.isLogged ? 'auth' : 'no_auth',
  };

  const handleSendAnalytics = (): void => {
    const action = 'main_click_become_author';
    logFirebaseEvent(action, eventParams);
    sendGoogleAnalytics({
      action,
    });
    sendYandexAnalytics({
      action,
      goalParams: {
        ...eventParams,
        user_id: userInfo.profile.uuid ?? '',
      },
    });
  };

  const openSignUpModalHandler = useCallback(
    (evt: UIEvent<ReactNode>) => {
      if (
        isBrowser &&
        window.innerWidth >= MIN_WINDOW_WITH_FOR_AUTH_MODAL_AND_BURGER &&
        !router.asPath.includes(pageUrls.account.registration)
      ) {
        evt.preventDefault();
        dispatch(openModal(EUiModalTypes.signUp));
        logFirebaseEvent('popup_registration_view', {
          source: 'registration_form',
        });
        handleSendAnalytics();
      }
    },
    [dispatch],
  );

  const finalBlockRef = useRef<HTMLDivElement>(null);
  const [isInView, setInView] = useState(false);

  useEffect(() => {
    const handleScroll = (): void => {
      if (finalBlockRef.current) {
        const blockRect = finalBlockRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (blockRect.top < windowHeight && blockRect.bottom >= 0) {
          setInView(true);
        } else {
          setInView(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleButtonPublishClick = (): void => {
    handleSendAnalytics();
    router.push(pageUrls.books.index);
  };

  return (
    <div
      className={cn(s.block, {
        [s.isInView]: isInView,
      })}
      ref={finalBlockRef}
    >
      <HighlightTitle className={s.title}>
        Хотите, чтобы вашу историю прочитали?
      </HighlightTitle>

      {!userInfo.isLogged && (
        <Button
          href="/account/registration/"
          onClick={openSignUpModalHandler}
          target="_self"
        >
          Стать автором
        </Button>
      )}

      {userInfo.isLogged && (
        <Button onClick={handleButtonPublishClick}>Опубликовать</Button>
      )}
    </div>
  );
};
