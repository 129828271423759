import { useMediaQuery } from '@mui/material';
import Image from 'next/image';
import { FC } from 'react';

import { EBreakpoints } from 'constants/breakpoints';

import { mediaBreakpointDown } from 'utils/mediaBreakpointDown';

import { juryMembers } from './data';

import s from './EBukvaJury.module.scss';

export const EBukvaJury: FC = () => {
  const isMobile = useMediaQuery(mediaBreakpointDown(EBreakpoints.MD));

  return (
    <div className={s.root}>
      {juryMembers.map(item => (
        <div key={item.name} className={s.juryCard}>
          <Image
            src={item.image}
            width={isMobile ? 110 : 80}
            height={isMobile ? 110 : 80}
            className={s.juryImage}
            alt={item.name}
          />
          <p className={s.juryName}>{item.name}</p>
        </div>
      ))}
    </div>
  );
};
