import cn from 'classnames';
import type { FC } from 'react';

import s from './Divider.module.scss';

interface IDividerProps {
  className?: string;
}

export const Divider: FC<IDividerProps> = props => {
  const { className } = props;
  return <div className={cn(s.divider, className)} data-testid="divider" />;
};
