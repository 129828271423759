import cn from 'classnames';
import { LitresBanner } from 'modules/litres-banner/components/LitresBanner';
import type { FC } from 'react';

import { EBukvaPromo } from './components/EBukvaPromo';
import { FinalBlock } from './components/FinalBlock';
import { HelpsBlock } from './components/HelpsBlock';
import { OffersBlock } from './components/OffersBlock';
import { SuccessStoriesBlock } from './components/SuccessStoriesBlock';
import { WaitBlock } from './components/WaitBlock';

import s from './MainPage.module.scss';

export const MainPage: FC = () => {
  return (
    <>
      <div className={s.container}>
        <WaitBlock />
        <div className={s.block}>
          <OffersBlock />
        </div>
      </div>

      <div className={s.container}>
        <LitresBanner isMainPage />
      </div>

      <div className={cn(s.container)}>
        <div className={s.successStoriesBlock}>
          <SuccessStoriesBlock />
        </div>
      </div>

      <EBukvaPromo />

      <div className={cn(s.container, s.last)}>
        <div className={s.block}>
          <HelpsBlock />
        </div>
      </div>
      <FinalBlock />
    </>
  );
};
