export const SUCCESS_STORIES = [
  {
    id: 1,
    text: 'Ушла с завода и доказала всем: мои книги приносят деньги',
    name: 'Анна Батлук',
    genre: 'фэнтези',
    src: '/components/SuccessStoriesBlock/1.png',
  },
  {
    id: 2,
    text: 'Написал о своей профессии – и сразу получил «Дебют года»!',
    name: 'Андрей Поляков',
    genre: 'триллер, мистика',
    src: '/components/SuccessStoriesBlock/2.png',
  },
  {
    id: 3,
    text: 'Из бумаги в цифровой формат: история о любви к Самиздату',
    name: 'Юлия Евдокимова',
    genre: 'детективы',
    src: '/components/SuccessStoriesBlock/3.png',
  },
  {
    id: 4,
    text: 'Экранизировали книгу после публикации на Литрес',
    name: 'Анна Смолина',
    genre: 'приключения, реализм',
    src: '/components/SuccessStoriesBlock/4.png',
  },
  {
    id: 5,
    text: 'Из айтишника в писатели, и я ни о чем не жалею',
    name: 'Миша Чинков',
    genre: 'нон-фикшн',
    src: '/components/SuccessStoriesBlock/5.png',
  },
];
