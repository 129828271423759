import type { FC } from 'react';

import { HighlightTitle, HighlightSubtitle } from 'components/basic/Typography';

import { typografFixText } from 'utils/typograf';

import s from './Titles.module.scss';

interface ITitlesProps {
  title: string;
  subtitle: string;
}

export const Titles: FC<ITitlesProps> = ({ title, subtitle }) => {
  return (
    <div className={s.titles}>
      <HighlightTitle Tag="h2" className={s.title}>
        {title}
      </HighlightTitle>
      <HighlightSubtitle Tag="p" className={s.subtitle}>
        {typografFixText(subtitle)}
      </HighlightSubtitle>
    </div>
  );
};
