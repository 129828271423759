//TODO реализовать пример
import { logFirebaseEvent } from 'analytics/firebase';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import useCollapse from 'react-collapsed';

import { Icon, TIconTypes } from 'components/UIKit/Icon';
import { Subtitle } from 'components/basic/Typography';

import s from './Collapse.module.scss';

interface ICollapseProps {
  headerText: string;
  children: JSX.Element | JSX.Element[] | string;
  localStorageName?: string;

  initialOpened?: boolean;
  analyticsEventName?: string;
  className?: string;
  classNameBody?: string;
  arrowPosition?: 'left' | 'right';
  headerIcon?: TIconTypes;
  dataTestId?: string;
}

export const Collapse: FC<ICollapseProps> = ({
  headerText,
  children,
  initialOpened,
  localStorageName,
  analyticsEventName,
  className,
  classNameBody,
  arrowPosition = 'left',
  headerIcon,
  dataTestId,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  function toggleExpanded(): void {
    setExpanded((prevExpanded: boolean): boolean => {
      const newExpanded = !prevExpanded;

      if (analyticsEventName) {
        logFirebaseEvent(analyticsEventName, {
          action: prevExpanded ? 'close' : 'open',
        });
      }

      if (localStorageName) {
        localStorage.setItem(localStorageName, newExpanded ? 'yes' : 'no');
      }

      return newExpanded;
    });
  }

  useEffect(() => {
    if (initialOpened) {
      setExpanded(true);
    }
  }, []);

  return (
    <div>
      <Subtitle
        className={cn(s.header, className)}
        {...getToggleProps({
          onClick: () => {
            toggleExpanded();
          },
        })}
        dataTestId={dataTestId}
      >
        {arrowPosition === 'left' && (
          <Icon
            className={s.arrowLeft}
            icon={
              isExpanded ? 'chevronUpOutline16x16' : 'chevronDownOutline16x16'
            }
            size={16}
          />
        )}
        {!!headerIcon && (
          <Icon className={s.headerIcon} icon={headerIcon} size={24} />
        )}
        {headerText}
        {arrowPosition === 'right' && (
          <Icon
            className={s.arrowRight}
            icon={
              isExpanded ? 'chevronUpOutline16x16' : 'chevronDownOutline16x16'
            }
            size={16}
          />
        )}
      </Subtitle>
      <section className={cn(s.content, classNameBody)} {...getCollapseProps()}>
        {children}
      </section>
    </div>
  );
};
