import Image from 'next/image';
import type { FC } from 'react';

import { Illustration } from 'components/basic/Illustration';
import {
  BodyL700,
  BodyL400,
  H4,
  HighlightTitle,
  HighlightSubtitle,
} from 'components/basic/Typography';

import { typografFixText } from 'utils/typograf';

import { SUCCESS_STORIES } from './constants';

import s from './SuccessStoriesBlock.module.scss';

export const FirstStory: FC = () => {
  const story = SUCCESS_STORIES[0];

  return (
    <>
      <div className={s.about}>
        <Image src={story.src} height={56} width={56} alt={story.name} />
        <div className={s.info}>
          <BodyL700>{story.name}</BodyL700>
          <BodyL400 className={s.genre}>{story.genre}</BodyL400>
        </div>
      </div>

      <HighlightTitle className={s.modalTitle}>
        {typografFixText(story.text)}
      </HighlightTitle>

      <H4>{typografFixText('До того, как я стала автором Литрес')}</H4>

      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Моя история началась случайно – в 2017 году. Тогда я стеснялась представляться автором и подумать не могла, что за книги можно получать деньги – писала для души, благо декрет позволял не думать о том, что нужно работать.',
        )}
      </BodyL400>

      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Живу я в небольшом городе. Здесь находится сердце громадного масложирового холдинга, продукцию которого знает вся страна. В 2018 году я подала туда резюме. На собеседовании встал вопрос о моем хобби и разумеется, я рассказала о книгах. Компания ценит талантливых людей и на работу меня взяли. С этого момента на книги у меня времени не оставалось, творчество стояло на месте.',
        )}
      </BodyL400>

      <BodyL400 className={s.modalText}>
        {typografFixText(
          'В 2020 году меня повысили до руководителя службы. Ожидаемо, свободного времени стало еще меньше, хотя зарплата увеличилась. Каждый день превратился в каторгу, я смотрела на экран компьютера и думала: что я здесь делаю? В какой-то момент достигла точки кипения и написала заявление. Собиралась искать новую работу, но мое бывшее руководство позволило себе посмеяться: «И чем будешь зарабатывать? Неужели книгами?».',
        )}
      </BodyL400>

      <BodyL400 className={s.modalText}>
        {typografFixText(
          'И так меня это обидело, что первостепенной задачей стало заработать писательством. Хоть 1000 рублей, но заработать. Так что цель была определена и я начала искать варианты.',
        )}
      </BodyL400>

      <div className={s.modalQuote}>
        <Illustration
          imageType="quote"
          width={9}
          height={7}
          className={s.modalQuoteTop}
        />
        <HighlightSubtitle className={s.modalQuoteText}>
          {typografFixText(
            'Спустя полгода после увольнения с работы мой доход с книг был равен зарплате менеджера крупного холдинга, а спустя год – зарплате руководителя службы.',
          )}
        </HighlightSubtitle>
        <Illustration
          imageType="quote"
          width={9}
          height={7}
          className={s.modalQuoteBottom}
        />
      </div>

      <H4>
        {typografFixText('Как изменилась моя жизнь после публикации на Литрес')}
      </H4>

      <BodyL400 className={s.modalText}>
        {typografFixText(
          'На Литрес я зарегистрирована не была. Боялась, стеснялась, чувствовала себя «ненастоящим» автором. Но прошла курс от «Литрес Мастерской» и осмелилась зарегистрироваться на Литрес Самиздат: разместила и старые книги и новые впроцессники. Тогда же начала рассказывать в соцсетях о том, что пишу книги и это стало самым важным и тяжелым шагом. Многие не воспринимали всерьез, но большая часть знакомых поддержали меня. Появлялись новые читатели – люди, которые меня не знали. День за днем я наблюдала за тем, как растет статистика продаж, статистика запросов моего имени в сети и в какой-то момент поняла, что задача заработать 1000 рублей книгами давно перевыполнена.',
        )}
      </BodyL400>

      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Спустя полгода после увольнения с работы мой доход с книг был равен зарплате менеджера крупного холдинга, а спустя год – зарплате руководителя службы. Меня показывали по местному телевидению, писали в газетах, основной моей деятельностью стали книги и их реклама. Пришло и издательство – редактор написал мне в соцсетях, а для меня печатная книга очень много значит.',
        )}
      </BodyL400>

      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Однако теперь никто не сможет уязвить меня фразой: «Ты своими книгами денег не заработаешь». Потому что я знаю – заработаю.',
        )}
      </BodyL400>
    </>
  );
};
