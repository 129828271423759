import Image from 'next/image';
import type { FC } from 'react';

import { Illustration } from 'components/basic/Illustration';
import {
  BodyL700,
  BodyL400,
  H4,
  HighlightTitle,
  HighlightSubtitle,
} from 'components/basic/Typography';

import { typografFixText } from 'utils/typograf';

import { SUCCESS_STORIES } from './constants';

import s from './SuccessStoriesBlock.module.scss';

export const FourthStory: FC = () => {
  const story = SUCCESS_STORIES[3];

  return (
    <>
      <div className={s.about}>
        <Image src={story.src} height={56} width={56} alt={story.name} />
        <div className={s.info}>
          <BodyL700>{story.name}</BodyL700>
          <BodyL400 className={s.genre}>{story.genre}</BodyL400>
        </div>
      </div>

      <HighlightTitle className={s.modalTitle}>
        {typografFixText(story.text)}
      </HighlightTitle>

      <H4>{typografFixText('До того, как я стала автором Литрес')}</H4>

      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Читатели моего блога спрашивали, появится ли книга о моих поездках. Сначала я не относилась к этому серьезно, а потом подумала: «я же умею интересно рассказывать, у меня неплохо получается писать». Так появился роман: «А чего дома сидеть? Вольный поход. Дилетант».',
        )}
      </BodyL400>
      <BodyL400 className={s.modalText}>
        {typografFixText(
          'А после того как пешком, с рюкзаком, я отправилась по высокогорью вдоль границы с Афганистаном, решила опубликовать книгу в электронном формате на Литрес.',
        )}
      </BodyL400>
      <div className={s.modalQuote}>
        <Illustration
          imageType="quote"
          width={9}
          height={7}
          className={s.modalQuoteTop}
        />
        <HighlightSubtitle className={s.modalQuoteText}>
          {typografFixText(
            'Продажи на Литрес выросли в 10 раз, если сравнивать с месяцами до выхода премьеры фильма. ',
          )}
        </HighlightSubtitle>
        <Illustration
          imageType="quote"
          width={9}
          height={7}
          className={s.modalQuoteBottom}
        />
      </div>
      <H4>
        {typografFixText('Как изменилась моя жизнь после публикации на Литрес')}
      </H4>
      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Мне кажется, продюсеров привлекла уникальность книги. В центре сюжета — нетривиальный поступок молодой женщины, который сдобрен понятными для всех проблемами с родителями. События сами складывались настолько кинематографично, что просились в экранизацию. Фильм вышел на большом экране и стал главной комедией весны 2023 «Поехавшая».',
        )}
      </BodyL400>
      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Я продолжаю публиковать книги на Литрес. У меня вышло уже четыре книги (в электронном и аудиоформате), и останавливаться я не планирую. Я принимала участие в конкурсе Литрес, победила и стала жюри. Это ли не карьерный рост?',
        )}
      </BodyL400>

      <BodyL400 className={s.modalText}>
        {typografFixText(
          'Теперь я чувствую резкий рост интереса к своей персоне. В соцсети пришла новая аудитория, изменилась и тональность при общении со мной. Это приятно. А продажи на Литрес выросли в 10 раз, если сравнивать с месяцами до выхода премьеры фильма. ',
        )}
      </BodyL400>
    </>
  );
};
